import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Quality Management System",
  "description": "The management system which controls on how quality is maintained by different companies, including ours, based on the ISO 9001.",
  "author": "Happy Fei",
  "categories": ["qc-testing"],
  "date": "2022-05-09T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["", "", "", ""]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Quality Management System (QMS) is the management system that directs and controls an organization in terms of quality. Established within the organization, they are the systematic quality management models necessary for the said organization to achieve its quality objectives. The system can be considered to be one of the organization’s strategic measures. `}</p>
    <p>{`As the resources are being processed, quality management is done with a systematic and process-focused approach. Based on the organization’s structure, QMS will control the process management, supply chain, and product realization (from basic measurements, analysis, until improvement) processes. `}</p>
    <p>{`By definition, an organization’s QMS covers the `}<strong parentName="p">{`planning, implementation, monitoring, correction, and improvement activities of completing a client’s needs`}</strong>{`. QMS will be operated during the designing and research processes, through production, inspection, sales up to delivery. Using the method of "doing what you say you’ll do and writing down what you did”, a QMS is an organization’s internal requirement for ensuring quality work. `}</p>
    <p>{`References: ISO 9001-2015 Standard Specification `}</p>
    <h2 {...{
      "id": "terms-and-definitions-for-qms"
    }}>{`Terms and Definitions for QMS`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Quality`}</strong>{`: the degree to which a set of inherent features satisfies the pre-designed requirements. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Process Management`}</strong>{`: a set of interrelated or interacting activities that transform inputs into outputs. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Quality Control (QC)`}</strong>{`: A product quality control can be divided into three stages: `}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`Incoming Quality Control (IQC)`}</strong>{`
This refers to inspection and confirmation of the quality of purchased raw materials, components or products (i.e. when a supplier sends raw materials or components, the product is sampled for inspection and judgment is passed on whether or not said batch will be kept or returned). `}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`In Process Quality Control (IPQC)`}</strong>{`
It's done to ensure that the goods being made by the Production Department meet necessary standards. This refers to checks that are done whenever a new batch of products is started or the production batch changes.
These inspections are primarily done for the purpose of monitoring and prevention, but they may also be done to ensure that items on the production line are being made to required tolerances. It means that, during official production, all products made will meet the quality requirements of the initial sample provided. Before IPQC is done, IQC is to confirm the quality of the incoming raw materials for the products which are going to be made. `}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`Final Quality Control (FQC)`}</strong>{`
It refers to the inspection carried out before the products are shipped to ensure that the shipped products meet the customers’ requirements. `}</p>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Quality Assurance (QA)`}</strong>{`
According to the international standards organization (ISO) this is "the assembly of all planned and systematic actions necessary to provide adequate confidence that a product, process, or service will satisfy given quality requirements.” `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Sampling plan`}</strong>{`
A set of plans for the sampling process, including the number of samples and sample judgment guidelines. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Acceptance Quality Level (AQL)`}</strong>{`
It’s the worst level allowed when a continuous series of batches is submitted for quality check. AQL is widely used in various industries’ product quality inspection, and different AQL standards are used in the inspection of different substances. In the process of AQL sampling, the same quantities of samples are taken for the assessment.The smaller the AQL value is, the fewer defects are allowed, the higher the quality requirements are, and the stricter the inspection is.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`5M1E (Man/Manpower, Machines, Materials, Methods, Measurements, and Environment)`}</strong>{`
These are the 6 general factors which can lead to fluctuation in product quality. `}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "related-standard-systems"
    }}>{`Related standard systems`}</h2>
    <ol>
      <li parentName="ol">{`ISO 9001: "Quality management systems — Requirements"`}</li>
      <li parentName="ol">{`ISO 9004: “Managing for the sustained success of an organization — A quality management approach”`}</li>
      <li parentName="ol">{`ISO 14001: “Environmental management systems - Requirements with guidance for use”`}</li>
      <li parentName="ol">{`OHSAS 18001: "Occupational Health and Safety Assessment Series — Scope"`}</li>
      <li parentName="ol">{`ISO 22000: " Food safety management systems — Requirements"`}</li>
      <li parentName="ol">{`HACCP: Hazard Analysis and Critical Control Point, Food Industry Safety and Hygiene Standards`}</li>
      <li parentName="ol">{`TL9000: "Telecommunication Industry Quality Management System Standards"`}</li>
      <li parentName="ol">{`AS9000: "Aerospace Basic Quality System Standard”`}</li>
      <li parentName="ol">{`IATF16949: "Automobile Parts Production and Related Service Quality Management System Requirements" `}</li>
    </ol>
    <h2 {...{
      "id": "iso-9001-overview-of-quality-system-documentation"
    }}>{`ISO 9001 Overview of Quality System Documentation`}</h2>
    <p>{`In response to the demand for a standardized quality management system, the ISO (International Organization for Standardization)’s Technical Committee on Quality Management and Quality Assurance has developed the `}<a parentName="p" {...{
        "href": "https://www.iso.org/iso-9001-quality-management.html"
      }}>{`ISO 9000`}</a>{` family of standards to apply to organizations dealing with products of different natures and sizes. `}</p>
    <p>{`The ISO 9000 family of standards is made up of a number of interrelated or complementary standards, including the well-known `}<strong parentName="p">{`ISO 9001 "Quality management systems — Requirements"`}</strong>{`. ISO 9001 has proposed requirements that are complementary to the product requirements and has been revised several times. On the basis of this standard, different industries have developed corresponding technical specifications, such as IATF 16949 Particular Requirements for the Application of ISO 9001:2015 for Automotive Production and Relevant Service Parts Organization, and ISO 13485 Quality Management System for Medical Devices: Requirements for Regulatory Purposes. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "446px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.40926640926641%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABJ0AAASdAHeZh94AAABv0lEQVQ4y3VSyarCQBDM//+DIAiO+5rNXUlMTIxBD15ED17Eg4IPxIsZ+1GtydPgayh6ptNTU9UT5fF40DdIKQlxu91I0zSaz+e8j6LoW3/0yr5CXwIf4xgOhzQajSifz9N2u01IUxEXfCV9GyJW5/s+ZTIZRi6Xo2w2S+fz+aPnX4Vpsv1+T61Wi2zbJsuyyHVd6na7VKvVkt63HL3ykzBNdrlcqNfr0WKxINM0GfV6nRzH4TpGkFIZvTieltOkOABFsNzv95lQVVWe52q1YpXr9fp9nn8K06/qeR4fGAwGTAby8XjMwBo1WMc8T6dTLOLTcky22+3YElSAQNd1VmYYBq8xU6zxC8EBLr7f76wwsRyT4fU6nQ4FQcCAwkajQc1mk+cHMuR2u82zXC6XrBQCQAieRCHmgANQA1KoQCPsxcAFMdADxVjDehiG0YuHCeVsNpO2bUvP86TruryfTqfScZykNplMeG9Z1kc9CAJZLBbvh8OBn0DZbDY8L7waVJVKJSqXy2ytUqmwbVgVQlChUODvqCGjBjdhGHL/9XoNlWq1etQ07aiq6lHX9X9hGEaC9xrOmqZ5EEL8CCHMX4D5kJjtlr/aAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/a5ef7b9fa8f4ccb97da13887e54a7baf/652f5/QMS-01.webp 259w", "/static/a5ef7b9fa8f4ccb97da13887e54a7baf/5021b/QMS-01.webp 446w"],
            "sizes": "(max-width: 446px) 100vw, 446px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/a5ef7b9fa8f4ccb97da13887e54a7baf/a2ead/QMS-01.png 259w", "/static/a5ef7b9fa8f4ccb97da13887e54a7baf/6244b/QMS-01.png 446w"],
            "sizes": "(max-width: 446px) 100vw, 446px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/a5ef7b9fa8f4ccb97da13887e54a7baf/6244b/QMS-01.png",
            "alt": "Hierarchy of QMS",
            "title": "Hierarchy of QMS",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`2015 Edition of Quality Management System: Quality Management Principles contains the following 7 aspects: `}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Customer-oriented`}</strong>{`: The main focus of quality management is to meet customer requirements and strive to exceed their expectations;`}</li>
      <li parentName="ol"><strong parentName="li">{`Role of leadership`}</strong>{`: Leaders at all levels shall establish a unified purpose and direction and create the conditions for coworking to achieve the quality objectives of the organization;`}</li>
      <li parentName="ol"><strong parentName="li">{`Full participation`}</strong>{`: Competence, empowerment, and involvement of people at all levels throughout the organization are necessary for the organization to improve its ability to create and deliver value;`}</li>
      <li parentName="ol"><strong parentName="li">{`Process approach`}</strong>{`: When activities are managed systematically as interrelated, functionally coherent processes, the desired results can be achieved consistently in a more effective and efficient manner;`}</li>
      <li parentName="ol"><strong parentName="li">{`Improvement`}</strong>{`: Successful organizations are always committed to continuous improvement;`}</li>
      <li parentName="ol"><strong parentName="li">{`Evidence-based decision making`}</strong>{`: Decisions based on analysis and evaluation of data and information are more likely to produce desired outcomes.`}</li>
      <li parentName="ol"><strong parentName="li">{`Relationship management`}</strong>{`: To be consistently successful, organizations need to manage relationships with related parties such as suppliers. `}</li>
    </ol>
    <h3 {...{
      "id": "nexpcbs-qs-documents-framework"
    }}>{`NexPCB's QS Documents Framework`}</h3>
    <p>{`The hierarchy of ISO 9001 quality management system documents is as follows. but of course, this hierarchy is not unique and the documents can be separated or combined. The following is the framework of quality system documents adopted by NexPCB:`}</p>
    <p><strong parentName="p">{`1. First-order document: Quality Manual`}</strong>{` `}</p>
    <p>{`A document describing the outline of the organization's quality policies, objectives, and system, generally including at least:`}</p>
    <ul>
      <li parentName="ul">{`Quality policies and goals.`}</li>
      <li parentName="ul">{`Organizational chart, and departmental responsibilities and authorities.`}</li>
      <li parentName="ul">{`The scope of the quality management system, including any details and justification for non-applicable aspects.`}</li>
      <li parentName="ul">{`The documented procedures prepared for the quality management system or references thereto.`}</li>
      <li parentName="ul">{`The description of the interactions between quality management system processes.
`}</li>
    </ul>
    <p><strong parentName="p">{`2. Second-order document: Procedure Document`}</strong>{` `}</p>
    <p>{`A procedure document is a document describing the step-by-step actions taken to implement the quality management system, which is an extended and more concrete version of the quality manual. It is made to ensure that the principles and systematic requirements in the quality manual can be developed and implemented.
The procedure document defines specific approaches to perform quality activities, including: `}</p>
    <ul>
      <li parentName="ul">{`The purposes and scopes of the actions.`}</li>
      <li parentName="ul">{`What to do, and who’s doing it. `}</li>
      <li parentName="ul">{`When, where and how to do the action. `}</li>
      <li parentName="ul">{`How to control and document the steps. `}</li>
      <li parentName="ul">{`How to control risks and opportunities in the process.`}</li>
      <li parentName="ul">{`How to monitor the process performance. `}</li>
    </ul>
    <p>{`The following are the main second-order procedure documents of NexPCB: `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Procedure Documents Name`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Planning Documents`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Risk Control Procedure`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Quality Objective Management Procedure`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Supporting Documents`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Equipment Management Procedure`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tooling Management Procedure`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Management Procedure for Monitoring and Measurement Devices`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Knowledge Management Procedure`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Training Management Procedure`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Document Control and Management Procedure`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operating Documents`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contract Management Procedure`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer Complaint Management Procedure`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Design and Development Control Procedure`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Supplier Management Procedure`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Purchasing Management Procedure`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Production Process Management Procedure`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Product Delivery Management Procedure`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Product Inspection and Control Procedure`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Non-conforming Product Control Procedure`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Performance Evaluation Documents`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer Satisfaction Survey Control Procedure`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Analysis and Evaluation Control Procedure`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Audit Control Procedure for Internal Quality Management System`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Improvement Documents`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Corrective Action Control Procedure`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Innovation Management Procedure`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`3. Third-order documents: Operating Guidance`}</strong>{` `}</p>
    <p>{`Operating documents define the routes of grassroots activities, including product inspections, specifications, guidelines, drawings, process documents, and forms. Operating documents also include procedure documents with lower levels and specific contents.
Despite this, not every procedure document has a number of detailed guidance. Only when the procedure documents can’t meet the specific requirements of certain activities, it is necessary to prepare operating guidance. The specific requirements are made based on different products, services, processes, departments, or jobs. `}</p>
    <p><strong parentName="p">{`4. Fourth-order documents: Records, etc.`}</strong>{` `}</p>
    <p>{`Includes each of the procedure documents’ records which state points to be controlled in every step. `}</p>
    <p>{`NexPCB has passed ISO9001 certification since `}<strong parentName="p">{`July 10, 2014`}</strong>{`, and has been focusing on quality system control to provide customers with quality-assured products. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "490px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "141.3127413127413%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAcCAIAAADuuAg3AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAFLUlEQVQ4y12U22/TVhzH81/sz2DSHjaJrcCm7WFvmzYx0DaJCVGGkEZbHjaJgYaAtmi8jD10gLRLmwu0XFraQi8kbW5NmsaNHcd2kuae2Ikd24lvcXw5Z0rS0ZWvfrIs/c7n9/Pve46P47NrT46Nek6MeY6PeY6NeYbGPEOj7g9GXUcvOY9ech0dcb0/4hoadQ+N9l7eG3G//cPckbHnR0bnP5946Uj5ZrGVaWzVia040TUnujaDrs6k1l2kz036PFQ/0DVnYmUGXXXuLE2/cP5xa/Luu8MPPvzlpQMeFgC9ZyjNePEaUmwu71biBU7VzV5qsACasEvvbc6N3Z522DYYhGXZg4AQ3F9L3XkWv/Rg05+qXHFGigzf1vWOYdo2MG1g9ss0qMibnS0bdIA1u110hfMrWMUdyj3fLdfSqGzoXdve7w1s2wYAgn3YNE2Kogr5PEYSVL5AkTiVobAkhqXwAIIQ2T2MoBLJJIaiDMP0p+vJ8d+ooNVqSe22qKiCLG8GQ0tenzcQ9AZDa5v+fKnclJSWrCiyrGna6888gAEAXV3PlcqFag3BkliKwPEkSRLbcQSn0rlKNVso6p2OZVlg4Or/4Xw+n0jsbkWjG6Hw4vLyZjDs9fm2IpFgLP7i5UpkF4vF40g8zrLcYP0+rOs6TdM8z7MsK0kyksS9fr8/FPYGgqEdhEhnkEQiWyxJ7bYkSYIgNJvNA9gwDIZhuL4EQWhJEp7OIDhOZtLJVCqeTOGFsigKgyzP84IgHMCWZdE0zTBMq9USeF4QxTiZ9ka2Qwk0jCYDCJpIkQ2OY1lWFEW2rwN4IE3TWI6jazUEwzCSiCUwfzwRRZMIhsVRbBdPsRzXEkXLsg65PShTLpfD4XC2UFzd2MRTeDC2Q1AUiiVDsR2f3x+IRJNEConHu93uIcMAAJqmmWbvALc7umpaUtdQDbPOMJIkaaalWbZiGFrXgBB2dH3AH8DNZrNerzM0XarWygxToplqgy2VSo16PV+rMRxXYhoVps42GhzHiaJ4aJ9fywZQNnonX7OBDaEFoNz/DxTLBvBN9WBRFNPpdDabrdG0ruuSqpHZPVoQm2JL7uiyYQq6YQLQS0mSKIqSJB3A9Xp9JxbbRRAURXmeV/Tu4tr6ejRWpOmmopTaSl1Wu5bdaLDlclkURVVVD2BJkqo1mq73NpBpsOlS5YuTX/3089USzeQq1TJdkxVVNsw6xzX7A3Mcd8htaOlAqgOjo3S7TVWZfvp80edXuzqvqOMPno7cmsLpJoQwHA67Pe5qtTqgenBL0//0J+ZD29niHoRwwjl//PzVT8fGF7cQC8ITwzfe+ujr7UwNQvjb3bunTp+KRKM9a23bASHQTTu2V8LJREfqlX8ciJ2/fe/y7/+QpQqE8MyNe+98d3WP5iGEa+vrbo8nl8vtdwb9GhBCnmUK+TyVyxWrlUylUqZ7rRRFDUZjgUgsnc0WCwUMS/I8bxjGvmEdvgghzJeqSZwgKQrH8V0US6ZSaZKkekHks9nCXpYkCBzHM5lMoVAgCKLRaPTge1N/6aUoNNvANnqXJ7Daqsa1JEFWGy25KStcW+bbsqR1LGCbtmXalmEN7k/oOPbj0unLU677U7FlNxlcyEdfRFbnvAuujQXX+pOZV8+c3ifOwIJ7a+lRLrScDS71Yzm9sZCLvnJ8c+fVJ9e9H19bOXlz/uz47NmbD8+Nzw5Pzn0/MXth8vGFybmLk7PD44/OXHefve6+MPHw3M2H315xfXn574u/Pv0XLHmajxJhROsAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/fe2cb34438f12d91c39e4cc230d138ea/652f5/QMS-02.webp 259w", "/static/fe2cb34438f12d91c39e4cc230d138ea/47e4b/QMS-02.webp 490w"],
            "sizes": "(max-width: 490px) 100vw, 490px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/fe2cb34438f12d91c39e4cc230d138ea/a2ead/QMS-02.png 259w", "/static/fe2cb34438f12d91c39e4cc230d138ea/41d3c/QMS-02.png 490w"],
            "sizes": "(max-width: 490px) 100vw, 490px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/fe2cb34438f12d91c39e4cc230d138ea/41d3c/QMS-02.png",
            "alt": "NexPCB QMS Certification",
            "title": "NexPCB QMS Certification",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <h2 {...{
      "id": "quality-process-control"
    }}>{`Quality Process Control`}</h2>
    <p>{`There is an important guiding idea in ISO9001: all work should be done through process control, and the theory is described as `}<strong parentName="p">{`Input - Process - Output`}</strong>{`. `}</p>
    <p>{`For example, the components and parts used to make a device are sourced with certain process parameters for production and manufacturing procedures until the product is qualified. Of course, each of the processes is not simple. Various constantly changing problems may arise throughout the process. `}</p>
    <p>{`Quality control refers to the operational techniques and actions undertaken to meet a specific set of quality requirements. The purpose of quality control is to `}<strong parentName="p">{`eliminate factors that may lead to non-conformity or unsatisfactory results at all stages of the quality loop by monitoring the quality formation process`}</strong>{`. To meet quality requirements and obtain economic benefits, a variety of quality operation techniques and activities will be used. `}</p>
    <p>{`Nexpcb carries out quality process control throughout the whole process from supplier evaluation, introduction, procurement, delivery, IQC inspection, IPQC process inspection, and FQC final inspection to shipping using process methods in terms of quality control:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.02316602316603%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABJ0AAASdAHeZh94AAAC0ElEQVQ4y1VSTW8URxCd/5ZTAOUHAP8BReKcA0YYWWAFb8SBROLASAlYBhvLKAJFQkKKsmubjZSLsXbt3fmenp2dj56v7p7pF1XjHFJSa2pqXle992qsO88+2jcfvcft7Q/q1uP3uHH/Hb69d4RrG0cmp+e1e1/z7x78jusb78y7qV9h6HzzwyG+/+UTrLvP/rDvv5zg8ZvPanQ4xdbrU2y+GmNr7xRP3k6x+WqCzd0JRod/Y3vvLzzcneDBy7HBEWbn7RRbeyfY+G2M5x/+gfX08NRWqgcAVfMCnrOAEi2SOMTF7AtEW0OJBrJrsLiYI1+v0MsOKYsQ+i7dQ1tz+O7CYKyd/Ymd8QZ936uYJXA9H0VZouQcWZ6j6wSKokTbdYgZA2GqugGvKoOhuhASg9Zo2w7W9u6f9tILIbpWrdIUS8c1QCGEOcMwIE1Tc2azGYIgQN00yLIMYRTBdV34fgDP91HXDawfX4/t2cJFr5Qi+mVZIs9zwyBdZ2AsQV4UkFIiTdemcVVVplHTtqBr9G29XqMTAtZo/9hO1gXaplFJkiAMQ3DOyQJzsW1bVFVtmF5eXiKOY9R1bdien5+bvGka+MScGD55M7aTrIQeBpXnBZaOYyQTS2JB06khBS9LYwO90/AoisxgKZVpZjwcHZzYVSu/brmuDcjILgoEYQSplPGLWFOdGBNutUoRx8wM/C8ot0YHxzZvhGnoOA6m06lhR95orQ0D2jYxowG0OGKyXDqYzee4st4Ebdv66eDkxVVDKaXUQgjNOddKKU0xDFp3Xaf7ftCLxVInyUp3ndBBEOil4+qBADQZ0EJIbe3sH/96JdlI8zwPRVGYf448JK/Ozs6MZKqVJTeyoziG63pmWf+T/PPR6dOsrEWvVLBarZjruizLcuZ6HpvN58zzfTafX7A0TVkYxSxdZ4zzikmpmFSKdZ1gQggmpGR107B/ATYNqeIPXmqKAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/9ba29f1e6779a3582c3028331079393d/652f5/QMS-03.webp 259w", "/static/9ba29f1e6779a3582c3028331079393d/c29d2/QMS-03.webp 518w", "/static/9ba29f1e6779a3582c3028331079393d/77d2c/QMS-03.webp 1035w", "/static/9ba29f1e6779a3582c3028331079393d/ba7d2/QMS-03.webp 1553w", "/static/9ba29f1e6779a3582c3028331079393d/9d755/QMS-03.webp 2070w", "/static/9ba29f1e6779a3582c3028331079393d/7be94/QMS-03.webp 2075w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/9ba29f1e6779a3582c3028331079393d/a2ead/QMS-03.png 259w", "/static/9ba29f1e6779a3582c3028331079393d/6b9fd/QMS-03.png 518w", "/static/9ba29f1e6779a3582c3028331079393d/e3189/QMS-03.png 1035w", "/static/9ba29f1e6779a3582c3028331079393d/44d59/QMS-03.png 1553w", "/static/9ba29f1e6779a3582c3028331079393d/a6d66/QMS-03.png 2070w", "/static/9ba29f1e6779a3582c3028331079393d/ae858/QMS-03.png 2075w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/9ba29f1e6779a3582c3028331079393d/e3189/QMS-03.png",
            "alt": "Quality Control Flowchart",
            "title": "Quality Control Flowchart",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h2 {...{
      "id": "common-quality-tools"
    }}>{`Common Quality Tools`}</h2>
    <p>{`Nexpcb uses many practical quality tools when dealing with quality issues or performing daily quality management activities, such as: `}</p>
    <h3 {...{
      "id": "i-seven-major-measures-for-qc"
    }}>{`I. Seven Major Measures for QC`}</h3>
    <p><strong parentName="p">{`1. Checklist`}</strong>{` `}</p>
    <p>{`The most important thing in quality control is to handle facts. To handle facts, you must use checklists to collect data.`}</p>
    <ul>
      <li parentName="ul">{`Record checklists: such checklists can be used to collect plan information and record the occurrence and data of a situation by making lines and points, which are often used in recording operations and quality conditions. `}</li>
    </ul>
    <p>{`e.g. Inspection Status Checklist `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Operator`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Machine number`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Defective type`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`5-1`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`5-2`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`5-3`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`……`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Total`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Size`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`6`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`50`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Appearance`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`9`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`11`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`101`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Material`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`40`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Other`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`18`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Size`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`6`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`7`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`8`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`51`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Appearance`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`8`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`8`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`9`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`106`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Material`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`38`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Other`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`27`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`B`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
        </tr>
      </tbody>
    </table>
    <ul>
      <li parentName="ul">{`Checklist for spot inspection
The checklist is used to confirm whether certain operations are completed in order to avoid any accidents (only records for yes/no or selection). In the checklist, the work or items should be listed and scored one by one. For example, the regular spot checklist for instrumentation and equipment`}</li>
    </ul>
    <p>{`e.g. Spot Checklist for Machine`}</p>
    <p><strong parentName="p">{`Machine Number: R11`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`Week: 24`}</strong>{` `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`No.`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Item	Date`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`1`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`2`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`3`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`4`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`5`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`6`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`7`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`√`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`B`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`√`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`C`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`√`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`D`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`√`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`E`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`√`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`6`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`√`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Maintained by`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CAC`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Reviewed by`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BTC`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
        </tr>
      </tbody>
    </table>
    <p>{`√: Good`}<br parentName="p"></br>{`
`}{`X: Abnormal `}</p>
    <p><strong parentName="p">{`2. Pareto chart`}</strong>{` `}</p>
    <p>{`In 1897, the Italian scholar Pareto analyzed the socio-economic structure and found that the majority of wealth was in the hands of a very small number of people, known as "Pareto's Law". Dr. Juran, an American quality expert, applied it to quality control and created the term "Vital Few, Trivial Many", which is called the "Pareto principle". `}</p>
    <p>{`In quality management, there are many reasons for the occurrence of defects, but there are only 1~3 items with significant impact. As long as these key points are identified, it will be easy to obtain solutions and realize improvements. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "539px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.79536679536679%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB3UlEQVQoz12SfW/aMBDG+f6fZ6VAA4TCpnX/TOtAJeTdLyRO7AQ7QBKC7SmBrsDpkXVn+afzPbpesvWKDHAaMuLT2HtU1Cn2aRJQEnSly4jPKRAM9Aj2igxF4AMDt2zjeKOyrKo2OR7KPCtzVjJaC56mEXCXgsErHEMriaB+CKV00+jjUR8Ouq510yiltNZ8xxJsf8EEWSQCUioppVRKai2rSuZ5ezZNe9PpLKXWOmNpDK0iQ1d4G35EOGhhpdT5rIRQVaW6Rq0+Q3ZwztII3MAEbUgErp8ty5a8YR7hLCVoczczhj4MgbdcFkJ086rPwR/hrO28vusMQ8c0zX7/ybIszrkQoq7r0+l072BnWH5nGEy2NgLu63xhGMZwOJxMp4PBYDyZvL392u/3u91u38ahLMtT0zBKvgwTrF0SEFiLuTkdjybG8HU2NkbPs6kxGjy9jPrD52/m5GU2Nb7PzZ8/FvZ6mXV7coV3acgpSLANvRWnALh/CwaBt+Q0jJHFYpfFXgytPAmQt+JpeHnfS7BXMMDTcJ9j6K+gv0qw7a5/cxr6mz858QPnPU/8CKwJ2sTQCpz3IkOCAkFBb4uCGPsXYeBg4GyhC4PNpYyQh4ATYx+GdpuHNgrt/+//ARYhx/Iy8QVdAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/2e558bf91d9a21fc8c58e6862392ea8c/652f5/QMS-04.webp 259w", "/static/2e558bf91d9a21fc8c58e6862392ea8c/c29d2/QMS-04.webp 518w", "/static/2e558bf91d9a21fc8c58e6862392ea8c/9cb26/QMS-04.webp 539w"],
            "sizes": "(max-width: 539px) 100vw, 539px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/2e558bf91d9a21fc8c58e6862392ea8c/a2ead/QMS-04.png 259w", "/static/2e558bf91d9a21fc8c58e6862392ea8c/6b9fd/QMS-04.png 518w", "/static/2e558bf91d9a21fc8c58e6862392ea8c/10f9a/QMS-04.png 539w"],
            "sizes": "(max-width: 539px) 100vw, 539px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/2e558bf91d9a21fc8c58e6862392ea8c/10f9a/QMS-04.png",
            "alt": "Pareto Chart",
            "title": "Pareto Chart",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p><strong parentName="p">{`3. Fishbone diagram`}</strong>{` `}</p>
    <p>{`It is also known as the `}<strong parentName="p">{`Cause & Effect diagram`}</strong>{`, which was first used in 1952 by Dr. Kaoru Ishikawa, an authoritative scholar on quality control in Japan, to clarify the relationship between cause and effect, also known as the `}<strong parentName="p">{`Ishikawa diagram`}</strong>{`. `}</p>
    <p>{`A cause & effect analysis chart is usually made by several people through brainstorming. Of course, it can also be done by one person, but the pool of ideas might not be that broad. `}</p>
    <p>{`The causes generally include manpower, machine, material, method, measurement and environment `}<strong parentName="p">{`(5M1E)`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "838px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "45.94594594594595%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABJ0AAASdAHeZh94AAAByElEQVQoz12R3a7TMBCE+/5vgLiBN+GGC4RAgNARoqdtmiatnT/HsZM4P94PJTocIUYaaXe9mrF2Dj6uTAgTUDtH2bbMQL8uVJ0lSGSSSGEa/DTtu9ssxBfKTtl2XFzrw1awLDBvnCEKxEitNd46qqIgy3KWabPZIPyLrRORfRgk1odpWQhpik0SuvRC4zxel/TXK8n5mdYNFJ3hXj+ojN1F4jzTnU70l8tu/tdl3AWBZRzJzidMb+nnAVVbCluQVXfye8lFXdGuwPWBvCq5VTecyhi6jq9Vy+eilsSNTCL1YbvBBms9c2sR5+hbT5objBtI05zLpUSVluSacT4/MGZANQufdODd8Ym3P77Ih7qhX7cfirCuK66xVOeEx+k3t9OZQnt6N+D9wuNuSZOM1ni07nEuYO3Mz3ri/fHIm+/f5KP1DLsgImEcJdNKSlvJXWvp+klscHIr79IYL93g5Hh7ltu92M4vr1kgot0gD+uim5ctlGoTJC4LxlqqtsYPPYVSXPOMpmtpW0OuHpSmJkzhJVr5P2xeUrYHF1c1iagZVNm2alhmNcZVGe+VbmrVhVGFGFXjnNJNs78Fia8cY1RD3OvSxfXXH6/Br7lpfbxBAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/f08b1a564c89720bd36559b35088a946/652f5/QMS-05.webp 259w", "/static/f08b1a564c89720bd36559b35088a946/c29d2/QMS-05.webp 518w", "/static/f08b1a564c89720bd36559b35088a946/7820a/QMS-05.webp 838w"],
            "sizes": "(max-width: 838px) 100vw, 838px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/f08b1a564c89720bd36559b35088a946/a2ead/QMS-05.png 259w", "/static/f08b1a564c89720bd36559b35088a946/6b9fd/QMS-05.png 518w", "/static/f08b1a564c89720bd36559b35088a946/a1dd2/QMS-05.png 838w"],
            "sizes": "(max-width: 838px) 100vw, 838px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/f08b1a564c89720bd36559b35088a946/a1dd2/QMS-05.png",
            "alt": "Fishbone Diagram",
            "title": "Fishbone Diagram",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <p><strong parentName="p">{`4. Histogram`}</strong>{`
A graph in which the relative frequencies of different levels of a variable are plotted in rectangular blocks using a normal distribution. The histogram can reflect the distribution of product quality by processing the seemingly disordered data collected to judge and predict product quality and failure rates. `}</p>
    <p>{`It can be used to:`}</p>
    <ul>
      <li parentName="ul">{`Verify the process capability as a basis for process improvement.`}</li>
      <li parentName="ul">{`Check the normality of the data distribution.`}</li>
      <li parentName="ul">{`Grasp the status of the process through the fluctuation of quality data to determine where improvements can be made. `}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.79536679536679%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACpklEQVQ4y22SS0wTURSGu2vCCqoJQ2lnWqBQQjSAsjQYkeiaRFF3JLAxkrB1gQkxgYUu1AiS8lJA3ZiwNTFo4kJjQkDiAyr03U6n0+ljKPNg2ulv5raVlyf5c+855893z9y5Jj62iwwfRJrzI8X5jimd8CMe3kJwZ5MoEfWS2klfivPpGT4ANrQdMqU4P2LBLQh8DGI6gWwqgWx5NXI+HiG9vWwSOVEo9Y6q5NfTSRbxyJ+QSYj7EPb/hqKoMKJYBIooy0gA7PgD+PzlK4KRyH88pKQrigw27C0Bo8FtKIpSNheJy1g1TSO1gcFBmEwmjI6NkTyfz5epxcqhuloBGncQCWwdA1Ymq8Td4WGYzWY8HB//Bywe9x4Ck+wuQr6fp4C6riPBJ5GTJAwMDZWAExOnDj0FjAZ+wefdhKqW7tAAGSGrKvpu9uN8ezvOdXTCYrHg/ugDaLqOjCiiUCgQVYDK0U9mw15IskxgWj4PAylKEi51d+OMxYKmlhbQNI2Ori50XriIeyMjOBGHExrvh4vuQDs4IJ2kIOBKby+uXrsOd1sbGIZBU4sbDocDDc3NqKmuxuWeHrxfXcWHj5/IIGRCWapM6EeS9SMYDmNtfR3vVlZQS1Got9nQ6HLB6XDA5XbD6XSiubUVjQ0NpF5jsZBVzOUI8EBVSg/b+Cn72ThmXy3CXFWFs1QdbAyDersdVrsdtbUU6hkGdVYrrDQDiqJgpWnYaBrOJhf6+m/hxu07+trGOjJ8qDKhD983vsHjmcTy6wXML3gwNz+NxaW5cu0lnk8+wZu3i5iaeoql5QV4ZiYxM/sC09PP8OjxuO7d3jQ4JaAohCFnY1D2OGgSD3WPI9L2eShinOxDuz+gKwL201HikbMsZDGOgixAzXG6LLLgIt7QX9bTTOxR6zDgAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/c6f5dc37a393175739d937a1c6152218/652f5/QMS-06.webp 259w", "/static/c6f5dc37a393175739d937a1c6152218/c29d2/QMS-06.webp 518w", "/static/c6f5dc37a393175739d937a1c6152218/77d2c/QMS-06.webp 1035w", "/static/c6f5dc37a393175739d937a1c6152218/c77ba/QMS-06.webp 1223w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/c6f5dc37a393175739d937a1c6152218/a2ead/QMS-06.png 259w", "/static/c6f5dc37a393175739d937a1c6152218/6b9fd/QMS-06.png 518w", "/static/c6f5dc37a393175739d937a1c6152218/e3189/QMS-06.png 1035w", "/static/c6f5dc37a393175739d937a1c6152218/fe8a7/QMS-06.png 1223w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/c6f5dc37a393175739d937a1c6152218/e3189/QMS-06.png",
            "alt": "Histogram",
            "title": "Histogram",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <p><strong parentName="p">{`5. Scatter diagram`}</strong>{` `}</p>
    <p>{`It is also called a correlation diagram, in which two pairs of data are collected to study the correlation between two variables, and the correlation between the values of the two characteristics is represented as points on a square grid and coordinate system. `}</p>
    <p>{`It can be used to discover, display and confirm the type and degree of correlation between two sets of related data and to confirm the expected relationship between them. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "568px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "78.76447876447877%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsTAAALEwEAmpwYAAABe0lEQVQoz5VT2W7CMBDk/z+wby0JFEEgEEqwvbertVMol9SuLCtrZ3ZmD89yzqKqZvn/Niu7HY9CdH/nIe0P4I85AmQARGT3CwaRVa9oRGYWkevJDIBztqYBxCxuev2b5A5MJMxyBTNrzta2AHCvCgqzmetXNREVUUS5l71YYAVbsXqXgGIkVWNWmDTrY87a/DAzK5HHVtEYCYBUlUWZS0PsWcHaBZxOTOQLkccxnU5pOAZm/fqKwxBC8FqWtH2vEi5gjNEjM2slT0DjGWrBxZmlZl6qMGmYZM8bSMlEhEgAOAT0ggGXXrzuc6mOLZeUkscrJM5MLDHh78l7HJhJ9nwOIRizME/NMMsJWMQ11lUPL9+/mRNiPp9DjHghIbqZsBDSk1aFkN/ebLXK7SI1Lex2tt5o1+nnijYb2W4n9/0jrtfcddp1Vh+CM4vkts19b4cBDgP2Pe562O6g7Km42O9xf8D9HjddGkdD9Hxm0ySCh3hliCS313UOvwFEqKzdeak9vgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/e98b15bb83036cb7da5837a614d9ff10/652f5/QMS-07.webp 259w", "/static/e98b15bb83036cb7da5837a614d9ff10/c29d2/QMS-07.webp 518w", "/static/e98b15bb83036cb7da5837a614d9ff10/bebff/QMS-07.webp 568w"],
            "sizes": "(max-width: 568px) 100vw, 568px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/e98b15bb83036cb7da5837a614d9ff10/a2ead/QMS-07.png 259w", "/static/e98b15bb83036cb7da5837a614d9ff10/6b9fd/QMS-07.png 518w", "/static/e98b15bb83036cb7da5837a614d9ff10/10e91/QMS-07.png 568w"],
            "sizes": "(max-width: 568px) 100vw, 568px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/e98b15bb83036cb7da5837a614d9ff10/10e91/QMS-07.png",
            "alt": "Scatter Diagram",
            "title": "Scatter Diagram",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <p><strong parentName="p">{`6. Control chart`}</strong>{` `}</p>
    <p>{`It is a statistically designed chart used to measure and record various characteristic values of a process to evaluate and monitor whether the process is under control. When control charts are used, they can be divided into the following two types:`}</p>
    <ul>
      <li parentName="ul">{`Analysis control charts that are mainly used to check if a process is under control. `}</li>
      <li parentName="ul">{`Management control charts that are used to maintain statistical control.  `}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "32.818532818532816%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABGklEQVQoz5VQXXODIBDk//+9PtRgMBodEcGPgk0kcFznaE3bl870Yed297hjgW2bq7zfeYyhDOEbpB+PR0aMsfTeZx8AfvUB4sFPIYQra5oGpRxwXVccR53RdT0aM+GyLLiub6i1QeccKjVi07S5DoPKM1KqPDPPS66sbTt8eXmNRXEGIRq4XK5wOgmo6y6jbXuoqivUdQtlWUFZCuBcgBA1cF59eRcoinPgvEKmlEKtdbLW5RTbtuH9fsfb7T0np1tJGzPnnnNbTk2cEn6evdFrorUW2TiOuO97QkRMKWUcHACe+vD+4JEqk1KilDJN04TGmH9Ba/1TRwrHvPdorU0Un5YS6BsIx2HiwzA8lxAnb57np+77PlL/A0W+EG7o+JWMAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/00e0c412c259f1dad884a9343d82ea95/652f5/QMS-08.webp 259w", "/static/00e0c412c259f1dad884a9343d82ea95/c29d2/QMS-08.webp 518w", "/static/00e0c412c259f1dad884a9343d82ea95/77d2c/QMS-08.webp 1035w", "/static/00e0c412c259f1dad884a9343d82ea95/ba7d2/QMS-08.webp 1553w", "/static/00e0c412c259f1dad884a9343d82ea95/ea6ef/QMS-08.webp 1746w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/00e0c412c259f1dad884a9343d82ea95/a2ead/QMS-08.png 259w", "/static/00e0c412c259f1dad884a9343d82ea95/6b9fd/QMS-08.png 518w", "/static/00e0c412c259f1dad884a9343d82ea95/e3189/QMS-08.png 1035w", "/static/00e0c412c259f1dad884a9343d82ea95/44d59/QMS-08.png 1553w", "/static/00e0c412c259f1dad884a9343d82ea95/baa75/QMS-08.png 1746w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/00e0c412c259f1dad884a9343d82ea95/e3189/QMS-08.png",
            "alt": "Control Chart",
            "title": "Control Chart",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <p><strong parentName="p">{`7. Stratification diagram`}</strong>{` `}</p>
    <p>{`The establishment of a method or condition that separates the matrices in order to distinguish the effects of various features of the collected data on the results is called stratification. There are different stratification perspectives:`}</p>
    <ul>
      <li parentName="ul">{`Stratification of time, such as by hours, days, weeks, and months.`}</li>
      <li parentName="ul">{`Stratification by operators.`}</li>
      <li parentName="ul">{`Stratification by machines and equipment.`}</li>
      <li parentName="ul">{`Stratification by operating conditions.`}</li>
      <li parentName="ul">{`Stratification by raw materials (e.g., different suppliers or different batches).`}</li>
      <li parentName="ul">{`and other stratification processes that will be set based on the on-site situation.`}</li>
    </ul>
    <p>{`e.g.  XXX Shooting Survey Form (by operators)`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Operators`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Number of misses`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Number of hits`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Incidence`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`A`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`6`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`13`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`0.32`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`B`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`16`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`0.25`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`C`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`9`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`0.53`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Total`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`19`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`31`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`0.38`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "ii-five-major-improvement-tools"
    }}>{`II. Five Major Improvement Tools`}</h3>
    <p>{`The following tools are more advanced for quality analysis and are often used in the automotive industry. Nexpcb may use them in dealing with some complex conditions: `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Measurement System Analysis (MSA)`}</strong><br parentName="p"></br>{`
`}{`The MSA combines 3 separate features: mathematical statistics, statistical variance analysis, and the study of the influencing factors that make up the measurement system. These assessments are performed to obtain conclusions about the accuracy and reliability of the measurement system. The quality of a measurement system usually depends only on the statistical characteristics of the data produced over time, including Bias, Repeatability, Reproducibility, Linearity and Stability. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Failure Mode and Effects Analysis (FMEA)`}</strong><br parentName="p"></br>{`
`}{`This is an analysis method used to identify potential failure modes and their causes. It allows the analysis and evaluation of various possible risks, along with the improvement of product design and production processes based on the magnitudes of the risks.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Design Failure Mode and Effect Analysis (DFMEA)`}</strong><br parentName="p"></br>{`
`}{`It is a method used to control product quality during the design stage. The tool also acts as a control tool used to maintain product quality for formal production and delivery to customers during the design and development stage. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Process Failure Mode and Effect Analysis (PFMEA)`}</strong><br parentName="p"></br>{`
`}{`It is a tool used to prevent potential failures in terms of processes during the mold design phase, the trial mold and trial production phases, the pre-mass production phase, and the customer complaint phase for new parts. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Statistical Process Control (SPC)`}</strong><br parentName="p"></br>{`
`}{`It is a statistics-based quality management technique used to evaluate and monitor the various stages of the process. SPC is done to establish and maintain an acceptable and stable level of the process, thus ensuring that products and services could meet the specified requirements. The output of the process is then tested and judged by graphical and statistical methods to predict whether the output could meet customer requirements. This can be done with the help of specialized software and other tools, such as Minitab.`}<br parentName="p"></br>{`
`}{`For instance,`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`The stability of the process operation can be determined by a statistical tool, i.e. the control chart. `}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`The adequacy of the process capability can be achieved by Process capability analysis. `}</p>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Production Part Approval Process (PPAP)`}</strong><br parentName="p"></br>{`
`}{`PPAP is used to determine whether all requirements of the customer's engineering design records  and specifications have been properly understood and whether the production process has the potential capability to meet customer requirements in the actual production at the specified production rate. It is mainly used in the automotive industry. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Advanced Product Quality Planning (APQP)`}</strong><br parentName="p"></br>{`
`}{`It is a process used to identify and develop the steps needed to ensure that a product satisfies the customer's Structured Approach. The goal is to facilitate contact with each party involved to ensure that the required steps can be completed on time. Effective product quality planning relies on the senior management's commitment to strive to achieve customer satisfaction. Product quality planning is a structured approach that is primarily used in the automotive industry. `}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "iii-quality-improvement-tools"
    }}>{`III. Quality Improvement Tools:`}</h3>
    <p>{`Various quality tools can be used by the persons in charge to analyze and study occuring problems for improvement, such as by using the quality control cycles shown below. `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Quality Control Circle (QCC)`}</strong><br parentName="p"></br>{`
`}{`A small circle formed by a group of people (generally around 6 people) from the same, similar or complementary workplaces spontaneously to cooperate and pool their ideas to solve problems and issues that occur in terms of workplace, management and culture by following certain activity procedures and using tools such as the Seven QC Measures. It is a vivid form of quality control. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Six Sigma`}</strong><br parentName="p"></br>{`
`}{`Six Sigma, also known as 6 Sigma, is a management strategy that focuses on quality management and was introduced by Motorola in 1986. This strategy emphasizes setting extremely high goals, collecting data, and analyzing results to reduce defects in products and services. The principle behind Six Sigma is that if you can detect defects in your project, you should figure out how to systematically reduce them and make your project as perfect as possible. For a company to meet Six Sigma standards, its error rate cannot exceed 3.4 parts per million units.`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "iv-reliability-testing"
    }}>{`IV. Reliability Testing`}</h3>
    <p>{`Reliability is an important indicator of product quality and is an inherent property of a system like the capacity or power rating of the system. Nexpcb focuses on the reliability of its products by conducting it from the S1 stage throughout the lifecycle of the products. In the early stage of product development, i.e. the S1 ~ S3 stages, Nexpcb will focus on the properties and use requirements of products or materials for reliability testing. Nexpcb will design reliability testing plans uniquely based on each situation, and carry out reliability certification through SGS, CTI, and other professional institutions if necessary.`}</p>
    <p>{`Common reliability plans: `}</p>
    <p><strong parentName="p">{`Mechanical reliability:`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Mechanical fatigue test`}</strong>{`: The test is to simulate the use environment of a product or material and test its fatigue strength.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Tensile test`}</strong>{`: It is a tensile test for products, materials, and processes, which will simulate the tensile conditions that may occur during the use of the product.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Bending test`}</strong>{`: The purpose of the test is to determine the mechanical properties of a material when it is subject to a bending load, and it is one of the basic methods for testing the mechanical properties of materials. In a bending test, one side of the specimen will be subject to a one-way tensile force while the other side subject to a one-way compression force with the maximum positive stress on the surface of the specimen, which is sensitive to surface defects. Therefore, the bending test is often used to test the material surface defects such as carbonization or surface quenching layer quality. In addition, for brittle materials that are sensitive to eccentricity, it is not easy to use a tensile test to accurately determine the mechanical properties of the materials. Therefore, the bending test is commonly used to determine the flexural strength and relative comparison of the deformation capacity of the materials.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Mechanical shock test`}</strong>{`: Electronic equipment may be subject to mechanical shocks in production, transportation, and even application. The mechanical shock test simulates the impact generated during such processes to test the ability of a product to withstand the shocks. Reference standards include: GB/T 2423.5-2019/IEC 60068-2-27:2008, GJB 150.18A-2009, GJB 367A－2001, GJB 322A-1998, GJB 3947A-2009, GB/T 6587-2012, GJB 548B-2005, and GJB 360B-2009.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Drop test`}</strong>{`: The drop test is to simulate the drop of a packaged product on its edges, corners, and surfaces at different heights to the ground, to identify potential damage to the product. It’s also used to evaluate the height and impact strength of the product packaging components that can withstand after drop. This way, the package designs could be improved according to the actual conditions of products within the national standard scope.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Vibration test`}</strong>{`: This test can simulate various conditions that may be encountered during manufacturing, assembly, transportation, and application to verify the ability of the products to withstand vibrations. It is applicable to the research, development, quality management, and manufacturing in various industries such as electronics, mechanical products, electrical products, automobile parts, and toys. `}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Environmental reliability test:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Temperature cycle aging`}</strong>{`: This process is to simulate the aging of a product or material in use, so as to eliminate the machining stress generated in production. For example, the method is to maintain a product at a high temperature and a low temperature from -40 to 85 ℃ for 30 minutes each for 10 cycles, to effectively relieve mechanical stress generated by machining.`}</li>
    </ul>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`High-temperature aging`}</strong>{`: A test in which a product or material will be exposed to high temperatures and dry air. As the most commonly used test, it can be used for component and machine screening, aging tests, shelflife tests, accelerated life tests, and evaluation tests and it also plays an important role in the verification of failure analysis. In the process of high-temperature tests, extra attention should be given to the maximum withstand temperature limits of products and components. When the sample is put into the test box to maintain its heating uniformity, the gap between the sample and any wall of the box shall be at least 5cm. After the test, the sample shall be recovered to a stable state in the box, or be placed in an environment at normal temperature and humidity to recover to a stable state. Please refer to GJB150.3-86.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Low-temperature storage aging`}</strong>{`: It is a simulation test used to verify the reliability of a product or sub-material stored at low temperatures. The product is stored at a low temperature of -40 ℃ for 168 hours (the temperature can be determined based on actual test conditions) before the conventional performance test. Please refer to GJB150.4-86 Environmental Test Methods for Military Equipment: Low-Temperature Test.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Temperature shock test`}</strong>{`: The test can be used to test the ability of a material to  withstand an `}<strong parentName="p">{`instant change`}</strong>{` in temperature. It is applicable to electronic products, semiconductors, electronic circuit boards, metal materials, bearings, and other components which are exposed to or will undergo rapidly changing temperatures.`}<br parentName="p"></br>{`
`}{`The test can be done by immediately transferring a componentl from the high-temperature chamber (RT: 150 ℃) to the low-temperature chamber (RT ~ -40 ℃) after it is kept in the chamber for a period of time. This component will then be transferred from the low-temperature chamber back to the high-temperature chamber after a said period. The process will be carried out within a certain number of cycles.
These test requirements are different for different industries or manufacturers in accordance with the standard test method above. The reference standards of temperature shock boxes are GB/T2423.1.2, GB/T2423.2, GB10592, GJB150.3, GJB150.4, and others. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Salt spray test`}</strong>{`: It is an environmental test using artificial salt spray to assess the corrosion resistance of products or metal materials. The salt sprays could be divided into neutral salt spray, acidic salt spray, copper ion spray, cyclic salt spray, etc. The referred standard documents are GB/T 2423.17, IEC60068-2-11, ISO4628-3, ASTM B117, JIS-Z2371, JIS-G3141, GJB 150.11A-2009, MIL-STD-810F, MIL-STD-883E and other domestic or international standards.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Dustproof and waterproof test`}</strong>{`: It's a test for products with dustproof and waterproof requirements. Please refer to GB/T 4208-2017 Enclosure protection level (IP code) for the highest level of dust and water resistance. The purpose of the test is to evaluate the performance of the shell in resisting solids and water.`}<br parentName="p"></br>{`
`}{`The XX of IPXX shall be mainly checked with the first X representing dustproof level from 0 to 6 (top) and the second X representing waterproof level from 0 to 8 (top). Nexpcb will carry out this test from stage S1 to stage S3 of a product based on materials and processing properties.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`High temperature and high humidity test`}</strong>{`: The test is to simulate the high temperature and high humidity conditions. The simulation environment has a temperature of 85 ℃ and humidity of + 85%. Nexpcb will conduct this reliability test in the pre-development stage together with the water and dust resistance test.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`UV aging test`}</strong>{`: The test is to simulate the aging of the product in outdoor conditions under UV radiation. Common reference standards include ISO 4892-1, ASTM G-151, ASTM G-154, BS 2782-5, and SAE J2020 JISD 0205.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      